import React from "react";
import Logo from "../assets/images/logo.png";

function Header() {
  return (
    <div className="container-fluid">
      <div className="header d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <img alt="taksi" src={Logo} className="header-logo" />
          <ul className="justify-content-center align-items-center gap-3 ms-5 d-none d-md-flex">
            <li>
              <a href="#hakkimizda">Hakkımızda</a>
            </li>
            <li>
              <a href="#hizmetler">Hizmetler</a>
            </li>
            <li>
              <a href="#iletisim">İletişim</a>
            </li>
          </ul>
        </div>
        <a className="btn btn-primary" href="tel:05424089290">
          İletişime Geç
        </a>
      </div>
    </div>
  );
}

export default Header;
