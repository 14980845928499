import React from "react";
import { FaPhoneFlip } from "react-icons/fa6";
import maping from "./map";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";

function Footer() {
  return (
    <div className="footer">
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
        <div className="row">
          <div className="col-12 col-md">
            <div className="content">
              <h4>Hakkımızda</h4>
              <p>
              Merhaba! Biz Benim Taksim, sektörde konforlu ve güvenli yolculukları sizlere sunan bir ekibiz. Müşteri memnuniyetine odaklanarak, her an yanınızda olmak için buradayız. benim Taksim olarak, her yolculukta sizinle bir öykü yazmak bizim önceliğimiz.
              </p>
            </div>
          </div>
          <div className="col-12 col-md">
            <div className="content">
              <h4>İletişim</h4>
              <a href="tel:05424089290" className="d-flex align-items-center">
                <FaPhoneFlip className="icon" />
                <p className="p-0 m-0 ps-3">+90 542 408 9290</p>
              </a>
            </div>
          </div>

          <div className="col-12 col-md">
            <div className="content-base">
              <h4>Bulunduğumuz alanlar</h4>
              {maping.map((item, index) => (
                <p key={index}>
                  <a href={'#'+item.url}>{item.name}</a>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-content">
        <div className="row">
          <div className="col">
            <a href="https://www.facebook.com/checkpoint/1501092823525282/?next=https%3A%2F%2Fwww.facebook.com%2F">
              <FaFacebookF />
            </a>
          </div>
          <div className="col">
            <a href="https://twitter.com/i/flow/signup">
              <FaTwitter />
            </a>
          </div>
          <div className="col">
            <a  href="https://wa.me/5424089290">
              <RiWhatsappFill />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
