import React from "react";
import Intro from "../assets/images/intro1.jpg";
import Intro2 from "../assets/images/meydannew.jpeg";
import Intro3 from "../assets/images/ulu.jpeg";
import map from "../assets/images/map.png";
import { FaWhatsapp } from "react-icons/fa";
import { FaPhoneFlip } from "react-icons/fa6";
import { FaCar, FaRegClock } from "react-icons/fa";
import { MdSystemSecurityUpdateGood } from "react-icons/md";
import { BsFillTaxiFrontFill } from "react-icons/bs";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
function HomePage() {
  return (
    <div className="home-page">
      <div>
        <a  className="wp" href="https://wa.me/5424089290">
          <FaWhatsapp className="whatsapp-icon" />
        </a>
      </div>
      <section className="intro">
        <div className="row">
          <div className="d-flex flex-column-reverse flex-md-row justify-content-space-between align-items-center">
            <div className="col-12 col-md-6">
              <div className="intro-text">
                <a
                  href="tel:05424089290"
                  className="intro-text-phone d-flex d-md-none align-items-center"
                >
                  <FaPhoneFlip className="intro-text-phone-icon" />
                  <p className="p-0 m-0 ps-3">+90 542 408 9290</p>
                </a>
                <h1>Güvenli Sürüş, Kaliteli Hizmet!</h1>
                <h3>
                  Hoş Geldiniz! Sivas'ın kalbinde, taksi hizmetinde kalite ve güvenin adresi burası! Sivas Benim Taksim, ailenizin ve sizin tercihiniz olmaya aday bir taksi firmasıdır. Müşteri memnuniyetini ön planda tutarak, istediğiniz her an ve her yerde size kaliteli hizmet sunmaktan gurur duyuyoruz.</h3>
                  <ul>
                    <h2 className="mb-3 text-center fw-extrabold">Neden Biz?</h2>
                    <div>
                      <h4>Kalite ve Güven: </h4>
                      <li className="text-black mx-4 mb-3"> Sivas Benim Taksim olarak, sunduğumuz hizmetlerde kalite ve güveni birinci önceliğimiz olarak kabul ediyoruz. Her yolculukta güler yüzlü ve güvenilir sürücülerimizle yanınızdayız.</li>
                    </div>

                    <div>
                      <h4>Esnek Hizmet: </h4>
                      <li className="text-black mx-4 mb-3">İstediğiniz zaman, istediğiniz yerde sizi bekliyoruz. Sivas Benim Taksim, size ulaşım konusunda esneklik sağlayarak, sizin için en uygun çözümleri sunar.</li>
                    </div>
                    <div>
                      <h4>Sabit Yolcu ve Öğrenci Ulaşımı: </h4>
                      <li className="text-black mx-4 mb-3">Sivas Benim Taksim ailesi olarak, sabit yolcu ve öğrenci ulaşımında da yanınızdayız. Güvenilir ve düzenli bir ulaşım için bize güvenebilirsiniz.</li>
                    </div>
                  </ul>


                
                <a
                  href="tel:05424089290"
                  className="intro-text-phone d-none d-md-flex align-items-center"
                >
                  <FaPhoneFlip className="intro-text-phone-icon" />
                  <p className="p-0 m-0 ps-3">+90 542 408 9290</p>
                </a>
              </div>
            </div>
            <div className="col-6">
              <div className="intro-images float-right">
                <img alt="taksi" className="intro-images-img" src={Intro} />
                <img alt="taksi" className="intro-images-img1" src={Intro2} />
                <img alt="taksi" className="intro-images-img2" src={Intro3} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="hakkimizda"
        className="phone d-flex flex-column align-items-center justify-content-center"
      >
        <div className="row d-flex flex-column flex-md-row align-items-center">
          <div className="col-12 col-md-6">
            <div className="left pe-0 pe-md-4">
              <p>
              Selam! Benim Taksim'e hoş geldin! Yolculuklarını daha güvenli ve konforlu hale getirmek için buradayız. Araçlarımız her zaman hazır, fiyatlarımız uygun ve sabit. Sadece bir telefon uzaklığındayız, her an hizmetinizdeyiz. Her yolculukta bize güvendiğinizde, tek tercihiniz Benim Taksim olacak. Sizinle birlikte geçirdiğimiz her an, bizim için bir onur. Sorularınız veya ihtiyaçlarınız için bize her zaman +90 542 408 9290 numaralı telefondan ulaşabilirsin. Seninle yolculuk yapmak için sabırsızlanıyoruz!
              </p>
              <a
                href="tel:05424089290"
                className="pt-4 d-flex align-items-center"
              >
                <FaPhoneFlip className="left-phone-icon" />
                <h2 className="text-white p-0 m-0 ps-3">+90 542 408 9290</h2>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="right mt-5 mt-md-0">
              <img alt="taksi" className="phone-image" src={Intro} />
            </div>
          </div>
        </div>
      </section>

      <section className="porperties d-flex flex-column align-items-center justify-content-center">
        <div className="row">
        <div className="col">
          <div className="porperties-content not-border text-center">
            <FaCar className="porperties-content-icon" />
            <h3>Adil Fiyatlandırma</h3>
            <p>
              Aracımıza bindiğinizde başlayan adil fiyatlandırmamızla yolculuklarınızda her zaman uygun ücret ödeyeceksiniz.
            </p>
          </div>
        </div>

        <div className="col">
          <div className="porperties-content text-center">
            <MdSystemSecurityUpdateGood className="porperties-content-icon" />
            <h3>Güvenli Yolculuk</h3>
            <p>
              Yolculuklarınız boyunca güvenliğiniz bizim önceliğimizdir. Her an güvenilir bir şekilde seyahat edeceğinizden emin olabilirsiniz.
            </p>
          </div>
        </div>

        <div className="col">
          <div className="porperties-content text-center">
            <FaRegClock className="porperties-content-icon" />
            <h3>Hızlı ve Kısa Bekleme Süresi</h3>
            <p>
            Aracımıza binmeden önce ve sonra geçen zamanı en aza indiriyoruz. Hızlı ve kısa bekleme sürelerimizle sizleri zamansız bir şekilde hedefinize ulaştırıyoruz.
            </p>
          </div>
        </div>

        </div>
      </section>

      <section id="hizmetler" className="hizmetler-container">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="hizmetler">
              <BsFillTaxiFrontFill className="icon" />
              <h5>HavaAlanı Transferleri</h5>
              <p>Tüm hizmetler sizin için mevcuttur</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="hizmetler">
              <BsFillTaxiFrontFill className="icon" />
              <h5>Şehir Turu</h5>
              <p>Tüm hizmetler sizin için mevcuttur</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="hizmetler">
              <BsFillTaxiFrontFill className="icon" />
              <h5>Noktadan Noktaya transfer</h5>
              <p>Tüm hizmetler sizin için mevcuttur</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="hizmetler">
              <BsFillTaxiFrontFill className="icon" />
              <h5>Etkinlik Taşımacılığı</h5>
              <p>Tüm hizmetler sizin için mevcuttur</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="hizmetler">
              <BsFillTaxiFrontFill className="icon" />
              <h5>Şehirler Arası yolculuk</h5>
              <p>Tüm hizmetler sizin için mevcuttur</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="hizmetler">
              <BsFillTaxiFrontFill className="icon" />
              <h5>Şöförlük</h5>
              <p>Tüm hizmetler sizin için mevcuttur</p>
            </div>
          </div>
        </div>
      </section>

      <section className="map-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <a href="https://www.google.com/maps/place/K%C3%BCmbet+Taksi,+58000+%C3%87elebiler%2FMerkez%2FSivas/data=!4m2!3m1!1s0x407ea9212037627d:0xef560cb426185716?utm_source=mstt_1&entry=gps&lucs=,47075915&g_ep=CAESCjExLjExMS4yMDIYACCIJyoJLDQ3MDc1OTE1QgJUUg%3D%3D">
              <img className="map-img w-100 m-w-md-75" src={map} alt="" />
            </a>
          </div>
          <div className="col-12 col-md-6">
            <div className="map-text-content  my-4 my-md-0 d-flex flex-row flex-md-col justify-content-between justify-content-md-center align-items-center h-100">
              <div className="map-text w-50 w-md-100 text-center text-md-left">
                <h3>Yorumlarınız bizim için değerli</h3>
              </div>
              <button
                className="btn btn-primary"
                onClick={() => {
                  window.location.href =
                    "https://www.google.com/maps/place/K%C3%BCmbet+Taksi,+58000+%C3%87elebiler%2FMerkez%2FSivas/data=!4m2!3m1!1s0x407ea9212037627d:0xef560cb426185716?utm_source=mstt_1&entry=gps&lucs=,47075915&g_ep=CAESCjExLjExMS4yMDIYACCIJyoJLDQ3MDc1OTE1QgJUUg%3D%3D";
                }}
              >
                Yorum yap
              </button>
            </div>
          </div>
        </div>
      </section>
      <section
        id="iletisim"
        className="call-we d-flex flex-column align-items-center justify-content-center"
      >
        <div className="d-flex flex-column align-items-center justify-content-center text-center text-md-left">
          <h2 className="pb-4">Kaliteli Yolcululuklar İçin</h2>
          <a href="tel:05424089290" className="btn btn-secondary call-me-button">
            <div className="d-flex justify-content-center align-items-center">
              <FaPhoneFlip className="left-phone-icon icon fs-4" />
              <p className="m-0 ps-4 fs-4">Bizi Arayın</p>
            </div>
          </a>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
